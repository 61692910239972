<template>
  <v-container fluid>
    <base-page-title>Company Profiles</base-page-title>
    <base-navigation-button
      tooltipText="Back to Project Index"
      nextRoute="projects"
      navIcon="mdi-chevron-left"
      color="warning"
      route
      :next="false"
    />
    <v-row class="mx-5 my-3">
      <v-col cols="12" md="6">
        <CompanyFormModal @saveData="saveCompanyProfile" :editMode="false" />
      </v-col>
      <v-col cols="12" md="6" class="d-flex align-end justify-end">
        <v-btn text color="secondary" @click="showToggler">
          {{ showAllTables ? "Collapse All" : "Expand All" }}</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels multiple focusable v-model="panel">
          <v-expansion-panel
            v-for="(company, index) in mappedCompanyList"
            :key="index"
          >
            <v-expansion-panel-header class="title">
              {{ company.name }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="9">
                  <v-row
                    v-if="
                      company.userList ? company.userList.length > 0 : false
                    "
                  >
                    <v-col cols="12">
                      <div class="pl-4 my-5 body-2 font-weight-bold">
                        Users:
                      </div>
                      <v-chip
                        v-for="(user, index) in company.userList"
                        :key="index"
                        color="secondary"
                        text-color="white"
                        class="ml-3 body-1"
                        >{{ user.name }}
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              right
                              v-on="on"
                              @click="
                                showDialog({
                                  id: user.pk.toString(),
                                  nextAction: `removeUser`,
                                  message: `Are you sure you want to remove ${user.name} from ${company.name}?`,
                                })
                              "
                            >
                              mdi-close
                            </v-icon>
                          </template>
                          <span>Remove User From Company</span>
                        </v-tooltip></v-chip
                      >
                    </v-col>
                  </v-row>
                  <v-btn
                    v-if="company.nda"
                    class="mt-5"
                    color="secondary"
                    @click="onDownloadNDA(company.nda)"
                    >Download NDA on file</v-btn
                  >
                </v-col>
                <v-col cols="12" md="3">
                  <v-row class="mt-2">
                    <v-col>
                      <CompanyFormModal
                        @saveData="saveCompanyProfile"
                        :editMode="true"
                        :company="company"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn
                        block
                        color="warning"
                        @click="
                          showDialog({
                            id: company.id.toString(),
                            nextAction: `deleteCompany`,
                            message: `Are you sure to delete company ${company.name}?`,
                          })
                        "
                        :data-cy="`company-profile-delete-button-${company.id}`"
                        >Delete Company</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <CreateRelationModal
                        toolbarText="User"
                        labelText="User Name"
                        :company="company"
                        :arrayList="results"
                        @searchCreateRelation="onSearchCreateRelation"
                        @onCreateRelation="createRelation"
                        @onClearList="clearList"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="2000" :color="snackColor" centered>
      <v-container class="body-1 py-0">
        <v-row dense>
          <v-col class="d-flex justify-center">
            <div>
              {{ snackText }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-snackbar>
    <BaseBottomDialog
      :dialog="bottomDialog"
      :message="confirmMessage"
      :action="confirmAction"
      :selected="selected"
      @deleteCompany="onDeleteCompany"
      @removeUser="onRemoveUser"
      @donothing="stopDialog"
    />
  </v-container>
</template>
<script>
import BottomDialog from "@/mixins/BottomDialog"
import { fileDownloader } from "@/helpers/basehelper.js";
export default {
  name: "CompanyPage",
  mixins: [BottomDialog],
  data() {
    return {
      panel: [],
      btnCollapse: null,
      results: null,
      snack: false,
      snackColor: "primary",
      snackText: "Company profile has been saved.",
    };
  },
  components: {
    CompanyFormModal: () => import("../components/CompanyFormModal.vue"),
    CreateRelationModal: () =>
      import("@/modules/user-profile/components/CreateRelationModal.vue"),
  },
  computed: {
    companyList() {
      return this.$store.getters["projects/companyList"];
    },
    mappedCompanyList() {
      return this.companyList.map((element) => ({
        ...element,
        userList:
          Object.keys(element.users).length > 0
            ? Object.entries(element.users).map(([k, v]) => ({
                pk: k,
                name: v,
              }))
            : null,
      }));
    },
    companyUserCount() {
      return this.mappedCompanyList.userList.length;
    },
    showAllTables() {
      return this.panel.length > 0;
    },
  },
  methods: {
    fileDownloader,
    async saveCompanyProfile(payload) {
      try {
        const response = await this.$store.dispatch("projects/updateCompanyById", payload);
        console.log(response);
        this.panel.push(this.mappedCompanyList.length - 1);
        this.snackText = "Company profile has been saved.",
        this.snack = true;
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    onDownloadNDA(payload) {
      const actionPayload = {
        url: payload,
        file: payload.split("/").pop(),
      };
      this.fileDownloader(actionPayload);
    },
    showToggler() {
      this.panel = !this.showAllTables
        ? [...Array(this.mappedCompanyList.length).keys()].map((k, i) => i)
        : [];
    },
    async onDeleteCompany() {
      this.stopDialog();
      try {
        await this.$store.dispatch("projects/deleteCompany", this.selected);
        this.snackText = "Company has been removed.",
        this.snack = true;
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
      this.selected = null;
    },
    async onSearchCreateRelation(payload) {
      console.log("Search is clicked with string: ", payload);
      try {
        const response = await this.$store.dispatch("projects/searchUsers", {
          params: { search: payload },
        });
        this.results = response.map((element) => ({
          id: element.pk,
          name: element.email,
        }));
        console.log(this.results);
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async createRelation({ id, company }) {
      console.log("Item is selected with id: ", id, Object.keys(company.users));
      if (Object.keys(company.users).includes(id.toString())) {
        this.$store.commit("ui/SNACK_BAR", "User already part of the Company.");
        this.clearList();
        return;
      }
      try {
        this.$store.dispatch("ui/loading", "Adding user");
        await this.$store.dispatch("projects/patchUserToCompany", {
          id,
          data: {
            groups: [company.id],
          },
        });
        this.clearList();
        this.snackText = "User has been added.",
        this.snack = true;
        this.$store.dispatch("ui/loading");
      } catch (err) {
        this.$store.dispatch("ui/loading");
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    clearList() {
      this.results = null;
    },
    async onRemoveUser() {
      this.stopDialog();
      try {
        this.$store.dispatch("ui/loading", "Removing user");
        await this.$store.dispatch("projects/patchUserToCompany", {
          id: this.selected,
          data: {
            groups: [],
          },
        });
        this.$store.dispatch("ui/loading");
        this.snackText = "User has been removed.",
        this.snack = true;
      } catch (err) {
        this.$store.dispatch("ui/loading");
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
      this.selected = null;
    },
  },
  mounted() {
    this.panel = this.mappedCompanyList
      ? [...Array(this.mappedCompanyList.length).keys()].map((k, i) => i)
      : [];
  },
};
</script>
