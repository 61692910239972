var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('base-page-title',[_vm._v("Company Profiles")]),_c('base-navigation-button',{attrs:{"tooltipText":"Back to Project Index","nextRoute":"projects","navIcon":"mdi-chevron-left","color":"warning","route":"","next":false}}),_c('v-row',{staticClass:"mx-5 my-3"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('CompanyFormModal',{attrs:{"editMode":false},on:{"saveData":_vm.saveCompanyProfile}})],1),_c('v-col',{staticClass:"d-flex align-end justify-end",attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":_vm.showToggler}},[_vm._v(" "+_vm._s(_vm.showAllTables ? "Collapse All" : "Expand All"))])],1)],1),_c('v-row',[_c('v-col',[_c('v-expansion-panels',{attrs:{"multiple":"","focusable":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.mappedCompanyList),function(company,index){return _c('v-expansion-panel',{key:index},[_c('v-expansion-panel-header',{staticClass:"title"},[_vm._v(" "+_vm._s(company.name)+" ")]),_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[(
                    company.userList ? company.userList.length > 0 : false
                  )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"pl-4 my-5 body-2 font-weight-bold"},[_vm._v(" Users: ")]),_vm._l((company.userList),function(user,index){return _c('v-chip',{key:index,staticClass:"ml-3 body-1",attrs:{"color":"secondary","text-color":"white"}},[_vm._v(_vm._s(user.name)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"right":""},on:{"click":function($event){_vm.showDialog({
                                id: user.pk.toString(),
                                nextAction: `removeUser`,
                                message: `Are you sure you want to remove ${user.name} from ${company.name}?`,
                              })}}},on),[_vm._v(" mdi-close ")])]}}],null,true)},[_c('span',[_vm._v("Remove User From Company")])])],1)})],2)],1):_vm._e(),(company.nda)?_c('v-btn',{staticClass:"mt-5",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.onDownloadNDA(company.nda)}}},[_vm._v("Download NDA on file")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-row',{staticClass:"mt-2"},[_c('v-col',[_c('CompanyFormModal',{attrs:{"editMode":true,"company":company},on:{"saveData":_vm.saveCompanyProfile}})],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"block":"","color":"warning","data-cy":`company-profile-delete-button-${company.id}`},on:{"click":function($event){_vm.showDialog({
                          id: company.id.toString(),
                          nextAction: `deleteCompany`,
                          message: `Are you sure to delete company ${company.name}?`,
                        })}}},[_vm._v("Delete Company")])],1)],1),_c('v-row',[_c('v-col',[_c('CreateRelationModal',{attrs:{"toolbarText":"User","labelText":"User Name","company":company,"arrayList":_vm.results},on:{"searchCreateRelation":_vm.onSearchCreateRelation,"onCreateRelation":_vm.createRelation,"onClearList":_vm.clearList}})],1)],1)],1)],1)],1)],1)}),1)],1)],1),_c('v-snackbar',{attrs:{"timeout":2000,"color":_vm.snackColor,"centered":""},model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_c('v-container',{staticClass:"body-1 py-0"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex justify-center"},[_c('div',[_vm._v(" "+_vm._s(_vm.snackText)+" ")])])],1)],1)],1),_c('BaseBottomDialog',{attrs:{"dialog":_vm.bottomDialog,"message":_vm.confirmMessage,"action":_vm.confirmAction,"selected":_vm.selected},on:{"deleteCompany":_vm.onDeleteCompany,"removeUser":_vm.onRemoveUser,"donothing":_vm.stopDialog}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }